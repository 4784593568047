import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { HOUSE_TYPES_NL } from '@energiebespaarders/constants';
import useForm, { isInvalidPostcode, isMissing } from '@energiebespaarders/hooks/useForm';
import { Box, Button, Flex, Select, TextLink } from '@energiebespaarders/symbols';
import { Book, Small, Smaller } from '@energiebespaarders/symbols/helpers';
import { useIsMobile } from '@energiebespaarders/symbols/hooks';
import { CaretLeft, MapSearch } from '@energiebespaarders/symbols/icons/solid';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useEventTracker from '~/hooks/useEventTracker';
import { useIsInitializing } from '~/hooks/useIsInitializing';
import useSuffixes from '~/hooks/useSuffixes';
import { BasicAddressFragment } from '../fragments';
import { useActiveHouseId } from '../hooks/useActiveHouseId';
import useIsAuthenticated from '../hooks/useIsAuthenticated';
import { useMeOptional } from '../hooks/useMe';
import { FIND_ADDRESS_FUNCTION, INITIALIZE_USER_HOUSE } from '../queries/house';
import { shade, themify } from '../styles/mixins';
import AddressFields from './AddressFields';
import {
  ALREADY_HAS_ADDRESS_ERROR,
  getAddressFieldsError,
  NON_RESIDENTIAL_MESSAGE,
} from './AddressFields/utils';
import type { Writable } from '../typeHelpers';
import type { BasicAddress_address } from '../types/generated/BasicAddress';
import type {
  findAddressFunction,
  findAddressFunctionVariables,
} from '../types/generated/findAddressFunction';
import type {
  getAddressByHouseId,
  getAddressByHouseIdVariables,
} from '../types/generated/getAddressByHouseId';
import type {
  initializeUserHouse,
  initializeUserHouseVariables,
} from '../types/generated/initializeUserHouse';
import type {
  myHouses,
  myHouses_me_Customer,
  myHouses_me_Customer_houses,
  myHouses_me_Lead,
  myHouses_me_Lead_houses,
} from '../types/generated/myHouses';
import type { AddressInput } from '../types/graphql-global-types';
import type { FormAddress } from './AddressFields';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import type { DropdownOption } from '@energiebespaarders/symbols/components/Select';
import type { ApolloError } from '@apollo/client';

// Keep in sync with GET_ME fields for Lead and Customer
export const MY_HOUSES = gql`
  ${BasicAddressFragment}
  query myHouses {
    me {
      id
      ... on Lead {
        houses {
          id
          ...BasicAddress
          constructionYear
          type
          created
        }
      }
      ... on Customer {
        houses {
          id
          ...BasicAddress
          constructionYear
          type
          requestedDuties {
            name
            requestedOn
          }
          created
          intake {
            id
            isStarted
          }
        }
      }
    }
  }
`;

export const GET_ADDRESS_BY_HOUSEID = gql`
  query getAddressByHouseId($houseId: ID!) {
    house(id: $houseId) {
      id
      address {
        zip
        number
        suffix
        street
        city
      }
    }
  }
`;

interface AddressInitFormProps {
  selectedHouseId: string;
  setSelectedHouseId: Dispatch<SetStateAction<string>>;
  setEditingCallback?: (v: boolean) => void;
  linkColor?: string;
  buttonBgColor?: string;
  buttonColor?: string;
  initialValues?: Partial<AddressInput>;
  getAddressCallback?: (address: BasicAddress_address) => void;
  isActive?: boolean;
  forPartnerInstantRequest?: boolean;
  buttonLabel?: string;
  buttonIcon?: ReactNode;
  validateAddress?: (address: FormAddress) => void;
  onHouseInitialized?: (houseId?: string) => void;
}

const AddressInitForm: React.FC<AddressInitFormProps> = ({
  selectedHouseId,
  setSelectedHouseId,
  setEditingCallback,
  linkColor = 'grayDark',
  buttonBgColor = 'green',
  buttonColor,
  initialValues,
  getAddressCallback,
  isActive = true,
  forPartnerInstantRequest,
  buttonLabel = 'Woninggegevens ophalen',
  buttonIcon = MapSearch,
  validateAddress,
  onHouseInitialized,
}) => {
  const { me } = useMeOptional();
  const isMobile = useIsMobile();
  const { activeHouseId, setActiveHouseId } = useActiveHouseId();
  const isAuthenticated = useIsAuthenticated();
  const trackEvent = useEventTracker();
  const [editingAddress, setEditingAddress] = useState(forPartnerInstantRequest);
  const [shouldValidateAddress, setShouldValidateAddress] = useState(false);
  const [activeAddress, setActiveAddress] = useState<BasicAddress_address | null>();
  const [addressError, setAddressError] = useState('');
  const [apolloError, setApolloError] = useState<ApolloError | undefined>();
  const { isInitializing } = useIsInitializing();

  const [initializeUserHouseMutation, { loading: initializingHouse }] = useMutation<
    initializeUserHouse,
    initializeUserHouseVariables
  >(INITIALIZE_USER_HOUSE, {
    onError: e => setApolloError(e),
    onCompleted: data => {
      setActiveHouseId(data.initializeUserHouse.id);
      onHouseInitialized?.(data.initializeUserHouse.id);
      void trackEvent('collect_home_info', {
        house_id: data.initializeUserHouse.id,
        construction_year: data.initializeUserHouse.constructionYear,
        house_type: HOUSE_TYPES_NL[data.initializeUserHouse.type],
        zip: data.initializeUserHouse.address.zip,
      });
    },
  });

  const {
    data: activeAddressData,
    refetch: getActiveAddress,
    loading: activeAddressLoading,
    error: activeAddressError,
  } = useQuery<getAddressByHouseId, getAddressByHouseIdVariables>(GET_ADDRESS_BY_HOUSEID, {
    skip: !activeHouseId,
    variables: { houseId: activeHouseId },
    onCompleted: data => data && getAddressCallback?.(data.house.address),
  });

  useEffect(() => {
    if (activeHouseId && !activeAddressData && !activeAddressLoading && !activeAddressError) {
      void getActiveAddress();
    }
  }, [
    activeHouseId,
    activeAddressData,
    getActiveAddress,
    activeAddressLoading,
    activeAddressError,
  ]);

  const { data: myHousesData, refetch: refetchMyHouses } = useQuery<myHouses>(MY_HOUSES, {
    // We need to wait until ME has been initialized
    // otherwise, queries might be done simultaneously, which initializes the lead twice,
    // which may cause the `me` to mismatch with the session cookie, causing auth issues
    skip: isInitializing,
  });

  const houses = useMemo(() => {
    let res: Writable<myHouses_me_Customer_houses | myHouses_me_Lead_houses>[] = [];
    if (myHousesData) {
      res = isAuthenticated.asCustomer
        ? [...((myHousesData?.me as Writable<myHouses_me_Customer>).houses || [])]
        : [...((myHousesData?.me as Writable<myHouses_me_Lead>).houses || [])];
    }
    return res;
  }, [isAuthenticated, myHousesData]);

  const addressOptions = useMemo((): { value: string; label: string }[] => {
    if (!houses || houses.length < 1) return [];
    return houses
      .sort((a, b) => (b.created > a.created ? 1 : -1))
      .map(({ id, address: { zip, number, suffix, street, city } }) => ({
        value: id,
        label: `${street} ${number}${suffix ? `-${suffix}` : ''}, ${zip} ${city}`,
      }));
  }, [houses]);

  useEffect(() => {
    if (houses.length === 0) {
      setSelectedHouseId('');
      setEditingAddress(true);
    }
  }, [houses, setSelectedHouseId]);

  const handleChangeSelectedAddress = useCallback(
    (e: DropdownOption<string>) => {
      setSelectedHouseId?.(e.value);
      setActiveHouseId(e.value);
      setEditingCallback?.(false);
    },
    [setSelectedHouseId, setActiveHouseId, setEditingCallback],
  );

  const { formState, handleChange, submitForm, submissionAttempted, resetSubmissionAttempted } =
    useForm<FormAddress>({
      initialValues: {
        zip: activeAddressData?.house.address.zip || initialValues?.zip || '',
        number: activeAddressData?.house.address.number || initialValues?.number,
        suffix: activeAddressData?.house.address.suffix || initialValues?.suffix || '',
      },
      handleSubmit: async () => {
        const address = {
          zip: formState.zip.value,
          number: formState.number.value,
          suffix: formState.suffix?.value ?? '',
        };

        const valid = !validateAddress || validateAddress?.(address);
        if (!valid) return;

        const existingHouse = houses.find(
          h =>
            h.address.zip === address.zip &&
            h.address.number === address.number &&
            h.address.suffix === address.suffix,
        );
        if (existingHouse) {
          setSelectedHouseId(existingHouse.id);
          setActiveHouseId(existingHouse.id);
          setEditingCallback?.(false);
        }

        if (!addressError && myHousesData?.me.id && !initializingHouse && !apolloError) {
          try {
            const res = await initializeUserHouseMutation({
              variables: {
                userId: me!.id || myHousesData.me.id,
                userType: me!.__typename.toLowerCase(),
                address,
              },
            });

            if (res?.data?.initializeUserHouse) {
              const house = res.data.initializeUserHouse;
              if (forPartnerInstantRequest) {
                setActiveAddress(house.address);
              } else {
                await refetchMyHouses();
              }
              setSelectedHouseId(house.id);
              setActiveHouseId(house.id);
              setEditingAddress(false);
              setEditingCallback?.(false);
            }
          } catch (e) {
            console.error(e);
            // existing address for user is handled through button text
            if (!(e as Error)?.message?.includes(ALREADY_HAS_ADDRESS_ERROR)) {
              throw e;
            }
          }
        }
      },
      validate: (values, errors) => {
        if (isMissing(values.zip) || isInvalidPostcode(values.zip)) {
          errors.zip = 'Postcode ongeldig';
        }
        if (isMissing(values.number) || values.number === 0) {
          errors.number = 'Nummer ongeldig';
        }
        return errors;
      },
    });

  useEffect(() => {
    if (formState.zip.value === '' && !formState.zip.touched && initialValues?.zip) {
      handleChange({ zip: initialValues.zip });
    }
    if (formState.number.value === 0 && !formState.number.touched && initialValues?.number) {
      handleChange({ number: initialValues.number });
    }
    if (formState.suffix?.value === '' && !formState.suffix.touched && initialValues?.suffix) {
      handleChange({ suffix: initialValues.suffix });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, handleChange]);

  const [fetchAddressFunction, { data: addressFunctionData, loading: addressFunctionLoading }] =
    useLazyQuery<findAddressFunction, findAddressFunctionVariables>(FIND_ADDRESS_FUNCTION, {
      fetchPolicy: 'network-only',
      onError: e => setApolloError(e),
    });

  useEffect(() => {
    if (houses.length > 0 && editingAddress) {
      if (selectedHouseId) {
        // When the selectedId changes (user enters another house), fetch the house data for it
        setEditingAddress(false);
      } else if (activeHouseId) {
        // In case the user lands on a route where this component loads, the houseId is not known on mount,
        // so select it when it becomes available
        setSelectedHouseId?.(activeHouseId);
        setEditingAddress(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeHouseId, houses, selectedHouseId, setSelectedHouseId]);

  const suffixFormStateValue = formState.suffix?.value || '';

  const { suffixes, loading: suffixesLoading } = useSuffixes(formState, setApolloError);

  useEffect(() => {
    if (
      isActive &&
      formState.zip.value &&
      formState.zip.value.length === 6 &&
      formState.number.value &&
      addressFunctionData?.findAddressFunction
    ) {
      void fetchAddressFunction({
        variables: {
          address: {
            zip: formState.zip.value,
            number: formState.number.value ?? 0,
            suffix: suffixFormStateValue,
          },
        },
      });
      if (!shouldValidateAddress) setShouldValidateAddress(true);
    }
  }, [
    formState.zip.value,
    formState.number.value,
    suffixFormStateValue,
    fetchAddressFunction,
    shouldValidateAddress,
    isActive,
    addressFunctionData?.findAddressFunction,
  ]);

  useEffect(() => {
    if (shouldValidateAddress) {
      const err = getAddressFieldsError(
        formState,
        addressFunctionData,
        suffixes,
        apolloError,
        false,
      );
      setAddressError(err);
      if (
        !err &&
        formState.zip.value === initialValues?.zip &&
        formState.number.value === initialValues?.number &&
        !initializingHouse
      ) {
        submitForm();
      }
    }
  }, [
    formState,
    addressFunctionData,
    apolloError,
    shouldValidateAddress,
    suffixes,
    initialValues,
    submitForm,
    initializingHouse,
  ]);

  useEffect(() => {
    if (addressError) {
      setTimeout(() => {
        setAddressError('');
        setApolloError(undefined);
        resetSubmissionAttempted();
      }, 5000);
    }
  }, [addressError, resetSubmissionAttempted]);

  const handleSetEditing = useCallback(() => {
    setShouldValidateAddress(false);
    setAddressError('');
    setApolloError(undefined);
    setEditingAddress(true);
    resetSubmissionAttempted();
    handleChange({ zip: '' });
    handleChange({ number: 0 });
    handleChange({ suffix: '' });
    setEditingCallback?.(true);
  }, [handleChange, setEditingCallback, resetSubmissionAttempted]);

  const isNonResidentialAddress = useMemo(
    () => addressError === NON_RESIDENTIAL_MESSAGE,
    [addressError],
  );

  const CancelChangeButton = useCallback(
    () =>
      houses && houses.length > 0 ? (
        <Box width={[1, 1 / 3]} px="3px">
          <Button
            fluid
            minimal
            iconStart={CaretLeft}
            fontSize={6}
            bgColor="grayDarker"
            onClick={() => {
              setEditingAddress(false);
              setEditingCallback?.(false);
            }}
            m={0}
            type="button"
          >
            Annuleren
          </Button>
        </Box>
      ) : (
        <></>
      ),
    [houses, setEditingCallback],
  );

  return (
    <>
      {editingAddress ? (
        <form onSubmit={submitForm}>
          <Flex flexWrap="wrap" mx="-3px">
            <Box width={1} px="3px">
              <AddressFields
                disabled={initializingHouse}
                formState={formState}
                handleChange={handleChange}
                labelColor="grayDarker"
                suffixes={suffixes}
                suffixesLoading={suffixesLoading}
              />
            </Box>

            {!isMobile && <CancelChangeButton />}

            <Box width={[1, houses && houses.length > 0 ? 2 / 3 : 1]} px="3px">
              <Button
                bgColor={buttonBgColor || linkColor}
                color={buttonColor}
                type="submit"
                fontSize={6}
                fluid
                iconStart={buttonIcon}
                loading={initializingHouse || addressFunctionLoading}
                loadTime={4}
                error={submissionAttempted ? addressError : undefined}
                label={initializingHouse ? 'Laden...' : buttonLabel}
                m={0}
              />
              {submissionAttempted && isNonResidentialAddress && (
                <Smaller>
                  We ondersteunen op dit moment alleen adressen met een woonfunctie. Zou dit adres
                  een woonfunctie moeten hebben, neem dan contact met ons op.
                </Smaller>
              )}
            </Box>

            {isMobile && <CancelChangeButton />}
          </Flex>
        </form>
      ) : forPartnerInstantRequest && activeAddress ? (
        <>
          <p>
            <Book>
              {activeAddress.street} {activeAddress.number}
              {activeAddress.suffix ? ` ${activeAddress.suffix}` : ''}, {activeAddress.zip}{' '}
              {activeAddress.city}
            </Book>
          </p>
        </>
      ) : (
        <>
          <Select<string>
            clearable={false}
            options={addressOptions}
            onChange={handleChangeSelectedAddress}
            label="Ingevulde adressen"
            labelColor="grayDarker"
            fontSize={6}
            value={addressOptions.find(
              option => option.value === selectedHouseId || option.value === activeHouseId,
            )}
          />
        </>
      )}
      {!editingAddress && (
        <Small>
          <p>
            <TextLink
              color={linkColor || 'green'}
              hoverColor={shade(0.8, themify(linkColor || 'green'))}
              onClick={handleSetEditing}
            >
              Ander adres gebruiken
            </TextLink>
          </p>
        </Small>
      )}
    </>
  );
};

export default AddressInitForm;
